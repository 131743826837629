<template>
  <v-container grid-list-md>
    <v-toolbar flat dense>
      <v-breadcrumbs
        class="px-0"
        :items="[
          {
            text: $t('vod.packages'),
            disabled: false,
            to: { name: 'packages' },
          },
          {
            text: item.title,
            disabled: true,
          },
        ]"
      ></v-breadcrumbs>
    </v-toolbar>
    <v-card outlined>
      <v-layout class="pb-0">
        <v-flex xs12>
          <v-card-title>{{ item.title }}</v-card-title>
          <v-simple-table>
            <tbody>
              <tr
                v-for="item in [
                  {
                    title: $t('vod.validFor'),
                    value: item.duration + ' ' + $t('vod.hours'),
                  },
                  {
                    title: $t('vod.moviesIncluded'),
                    value: moviesOfPackage.total,
                  },
                ]"
                :key="item.title"
              >
                <td>{{ item.title }}</td>
                <td>{{ item.value }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <v-divider></v-divider>
          <v-card-text v-if="item.description && item.description.length > 0">{{
            item.description
          }}</v-card-text>
        </v-flex>
      </v-layout>
      <v-card-text>
        <v-btn
          color="primary"
          block
          :disabled="item.already_bought"
          @click="
            buyDialog = true;
            setDialogData({ item: item });
          "
        >
          {{
            (item.price / 100) | currency($store.state.session.currencyConfig)
          }}
        </v-btn>
      </v-card-text>
    </v-card>
    <template
      v-if="moviesOfPackage.total > 0 || statusPackageMovies === 'fetching'"
    >
      <v-list
        subheader
        v-if="
          statusPackageMovies === 'success' ||
          statusPackageMovies === 'fetching'
        "
      >
        <v-subheader class="pl-0">{{ $t("vod.moviesInPackage") }}</v-subheader>
        <movie-list
          :status="statusPackageMovies"
          :items="moviesOfPackage.movies"
          disabled
        ></movie-list>
      </v-list>
    </template>
    <buy-dialog></buy-dialog>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import BuyDialog from "@/components/vod/BuyDialog";
import MovieList from "@/components/vod/MovieList";

export default {
  components: {
    BuyDialog,
    MovieList,
  },
  computed: {
    ...mapState({
      item: ({ vod }) => vod.packageById,
      authResult: ({ session }) => session.authResult,
      allPackages: ({ vod }) => vod.allPackages,
      moviesOfPackage: ({ vod }) => vod.moviesOfPackage,
      statusPackageById: ({ vod }) => vod.statusPackageById,
      statusBuyProcess: ({ vod }) => vod.statusBuyProcess,
      statusPackageMovies: ({ vod }) => vod.statusPackageMovies,
      buyDialogState: ({ vod }) => vod.buyDialogState,
    }),
    buyDialog: {
      get() {
        return this.buyDialogState;
      },
      set(val) {
        this.setBuyDialog({ state: val });
      },
    },
    loading() {
      return (
        this.statusPackageById === "fetching" ||
        this.statusPackageMovies === "fetching"
      );
    },
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapMutations({
      setLoading: "SET_LOADING",
    }),
    ...mapActions("vod", [
      "getPackageById",
      "getMoviesOfPackage",
      "buyMovieOrPackage",
      "setBuyDialog",
    ]),
    ...mapActions("buydialog", ["setDialogData"]),
    initData() {
      this.getPackageById({
        id: this.$route.params.pckgId,
      });
      this.getMoviesOfPackage({
        view: "detail",
        pckg_id: this.$route.params.pckgId,
      });
    },
  },
  watch: {
    loading(val) {
      this.setLoading(val);
    },
    statusBuyProcess(val) {
      if (val === "success") {
        this.buyDialog = false;
        this.initData();
      }
    },
  },
};
</script>
