var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"grid-list-md":""}},[_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-breadcrumbs',{staticClass:"px-0",attrs:{"items":[
        {
          text: _vm.$t('vod.packages'),
          disabled: false,
          to: { name: 'packages' },
        },
        {
          text: _vm.item.title,
          disabled: true,
        },
      ]}})],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-layout',{staticClass:"pb-0"},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.item.title))]),_c('v-simple-table',[_c('tbody',_vm._l(([
                {
                  title: _vm.$t('vod.validFor'),
                  value: _vm.item.duration + ' ' + _vm.$t('vod.hours'),
                },
                {
                  title: _vm.$t('vod.moviesIncluded'),
                  value: _vm.moviesOfPackage.total,
                },
              ]),function(item){return _c('tr',{key:item.title},[_c('td',[_vm._v(_vm._s(item.title))]),_c('td',[_vm._v(_vm._s(item.value))])])}),0)]),_c('v-divider'),(_vm.item.description && _vm.item.description.length > 0)?_c('v-card-text',[_vm._v(_vm._s(_vm.item.description))]):_vm._e()],1)],1),_c('v-card-text',[_c('v-btn',{attrs:{"color":"primary","block":"","disabled":_vm.item.already_bought},on:{"click":function($event){_vm.buyDialog = true;
          _vm.setDialogData({ item: _vm.item });}}},[_vm._v(" "+_vm._s(_vm._f("currency")((_vm.item.price / 100),_vm.$store.state.session.currencyConfig))+" ")])],1)],1),(_vm.moviesOfPackage.total > 0 || _vm.statusPackageMovies === 'fetching')?[(
        _vm.statusPackageMovies === 'success' ||
        _vm.statusPackageMovies === 'fetching'
      )?_c('v-list',{attrs:{"subheader":""}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v(_vm._s(_vm.$t("vod.moviesInPackage")))]),_c('movie-list',{attrs:{"status":_vm.statusPackageMovies,"items":_vm.moviesOfPackage.movies,"disabled":""}})],1):_vm._e()]:_vm._e(),_c('buy-dialog')],2)
}
var staticRenderFns = []

export { render, staticRenderFns }